import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_BACKGROUND } from '@theme_color';

const useStyles = makeStyles({
    containerDialog: {
        '& .MuiPaper-root': {
            maxWidth: '560px',
            minWidth: '524px',
            '@media (max-width: 767px )': {
                minWidth: 'unset',
            },
        },
        '& .MuiDialogContent-dividers.wrapper-search': {
            overflow: 'unset',
            paddingTop: 0,
            paddingBottom: 0,
            borderBottom: 0,
            border: 0,
        },
    },
    closeIcon: {
        fontSize: 27,
        float: 'right',
        cursor: 'pointer',
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        '& span': {
            fontSize: '24px',
            fontWeight: '600',
            margin: 0,
        },
    },
    item: {
        padding: '26px 0 26px 0',
        border: 0,
        borderBottom: `1px solid ${GRAY_BACKGROUND}`,
        '@media (max-width: 767px )': {
            rowGap: '5px',
        },
    },
    storeTitleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    storeName: {
        fontWeight: 'bold',
        textTransform: 'capitalize',
    },
    itemLeft: {
        // flexGrow: 1,
    },
    itemRight: {
        padding: '0',
        '& > div': {
            textAlign: 'right',
        },
    },
    street: {
        fontSize: 12,
        marginTop: '9px',
        color: '#667085',
    },
    buttonShopHere: {
        borderRadius: 4,
        textTransform: 'capitalize',
        width: '112px',
        minHeight: '40px',
        '& .MuiButton-label': {
            fontSize: '16px',
            fontWeight: 'bold',
        },
    },
    avaialable: {
        textAlign: 'left',
        marginLeft: '12px',
        fontSize: '12px',
        color: '#008060',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        '& .checkIcon': {
            marginRight: '6px',
            width: '15px',
            height: '15px',
        },
    },
    textField: {
        margin: 0,
    },
});

export default useStyles;
